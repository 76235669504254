import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CircularProgress from "@mui/material/CircularProgress";
import Card from '@mui/material/Card';
import { Button } from "@material-ui/core";
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(3)
  },
  tableHeader: {
    background: "#000000 !important",
    color: "#ffffff !important"
  }
}));

function SignificantEventsSection(props) {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [events, setEvents] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [time, setTime] = useState("");
  const [date, setDate] = useState(new Date());

  const deleteSignificantEvent = async (eventID) => {
    let request = "https://6ibkst7jq0.execute-api.us-east-2.amazonaws.com/test/significantevents?id=" + eventID;
    await fetch(request, {
      method: 'DELETE'
    })
    .then(
      (result) => {
        let currentEvents = events.filter(function (event) {
          return event.id !== eventID;
        });
    
        setEvents(currentEvents);
        setIsLoaded(false);
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        setIsLoaded(true);
        setError(error);
      }
    );
  }

  const submitSignificantEvent = async () => {
    await fetch("https://6ibkst7jq0.execute-api.us-east-2.amazonaws.com/test/significantevents",
    {
      method: "PUT",
      body: JSON.stringify({"title": title, "description": description, "date": date.toISOString().split('T')[0], "time": time})
    })
    .then(function(){ setIsLoaded(false); })
  }

  useEffect(async() => {
    await fetch(
      "https://6ibkst7jq0.execute-api.us-east-2.amazonaws.com/test/significantevents"
    )
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.rows !== []) {
            setEvents(result.records);
            setIsLoaded(true);
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, [isLoaded]);

  if(isLoaded){
    return (
      <Section
        bgColor={props.bgColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
      >
        <Container>
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            size={4}
            textAlign="left"
          />
        <Card sx={{ minWidth: 275, maxWidth: "50%", marginBottom: "25px" }}>
          <CardContent>
            <h1>Create Significant Event</h1>
            <div style={{width:"100%", height:"100%", display:"flex", "flexDirection":"column"}}>
              <div style={{display:"flex", marginBottom:"15px"}}>
                <TextField onChange={(val) => setTitle(val.target.value)} required style={{marginRight:"15px"}} id="outlined-basic" label="Title" variant="outlined" />
                <TextField onChange={(val) => setDescription(val.target.value)} style={{marginRight:"15px"}} id="outlined-basic" label="Description" variant="outlined" />
                <TextField onChange={(val) => setTime(val.target.value)} style={{marginRight:"15px"}} id="outlined-basic" label="Time" variant="outlined" />
              </div>
              <div style={{display:"flex"}}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    required
                    label="Select a Date"
                    value={date}
                    onChange={(newValue) => {
                      setDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                <Button onClick={() => submitSignificantEvent()} style={{marginLeft:"15px", padding:"0px 50px 0px 50px"}} variant="contained">Create</Button>
              </div>
            </div>
          </CardContent>
        </Card>
        {(events.length > 0) &&
          <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead style={{background:"black"}}>
              <TableRow>
                <TableCell style={{color:"white"}} align="left"><b>Title</b></TableCell>
                <TableCell style={{color:"white"}} align="left"><b>Description</b></TableCell>
                <TableCell style={{color:"white"}} align="left"><b>Date</b></TableCell>
                <TableCell style={{color:"white"}} align="left"><b>Time</b></TableCell>
                <TableCell style={{color:"white"}} align="center"><b>Actions</b></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {events.map((event) => (
                <TableRow
                  key={event.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="left">{event.title}</TableCell>
                  <TableCell align="left">{event.description}</TableCell>
                  <TableCell align="left">{event.date.split('T')[0]}</TableCell>
                  <TableCell align="left">{event.time}</TableCell>
                  <TableCell align="center">
                    <Button onClick={() => deleteSignificantEvent(event.id)}>
                      <DeleteForeverIcon style={{ color: "red" }} />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        }
        {(events.length === 0) && 
          <div style={{textAlign:"center"}}>
            <p>No Significant Events Found</p>
          </div>
        }
        </Container>
      </Section>
  );
} else {
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader title={props.title} size={4} textAlign="center" />
        {error === null && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        )}
        {error !== null && <div style={{ textAlign: "center" }}>{error}</div>}
      </Container>
    </Section>
  );
}
}

export default SignificantEventsSection;
