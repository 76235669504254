import React, { useState, useEffect } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { makeStyles } from "@material-ui/core/styles";

const breakdownBodyGen = function(data) {
    if(data !== {}){
        //Data Cleanup
        data = Object.entries(data);
        //Particular ordering for demo purposes
        let reorderedUSAStocks = [];
        if(data[1]){
            reorderedUSAStocks.push(data[1][1].filter(obj => { return obj.sector === "Large Cap"})[0]);
            reorderedUSAStocks.push(data[1][1].filter(obj => { return obj.sector === "Mid cap"})[0]);
            reorderedUSAStocks.push(data[1][1].filter(obj => { return obj.sector === "Small cap"})[0]);
            reorderedUSAStocks.push(data[1][1].filter(obj => { return obj.sector === "Financials"})[0]);
            reorderedUSAStocks.push(data[1][1].filter(obj => { return obj.sector === "REITs"})[0]);
            reorderedUSAStocks.push(data[1][1].filter(obj => { return obj.sector === "Energy"})[0]);
            reorderedUSAStocks.push(data[1][1].filter(obj => { return obj.sector === "Industrials"})[0]);
            reorderedUSAStocks.push(data[1][1].filter(obj => { return obj.sector === "Cons Disc"})[0]);
            reorderedUSAStocks.push(data[1][1].filter(obj => { return obj.sector === "Biotech"})[0]);
            reorderedUSAStocks.push(data[1][1].filter(obj => { return obj.sector === "Materials+Metals"})[0]);
            reorderedUSAStocks.push(data[1][1].filter(obj => { return obj.sector === "Housing"})[0]);
            reorderedUSAStocks.push(data[1][1].filter(obj => { return obj.sector === "Specialized"})[0]);
            reorderedUSAStocks.push(data[1][1].filter(obj => { return obj.sector === "Value"})[0]);
            data[1][1] = reorderedUSAStocks;
        }

        let sectors = [];
        data.forEach((obj) => {
            let payload = {sector: obj[0], data: obj[1]}
            sectors.push(payload);
        })

        let columnCount = 0;
        let tableBody = [];
        let subsectorRow = [<TableCell style={{background: "black"}} colSpan={1}></TableCell>];
        let nameRow = [<TableCell colSpan={1}><b>Name</b></TableCell>];
        let biasRow = [<TableCell colSpan={1}><b>Price Action Bias</b></TableCell>];
        let tradeDirectionRow = [<TableCell colSpan={1}><b>Divergences: Neg / Pos</b></TableCell>];
        let reversalsRow = [<TableCell colSpan={1}><b>Reversals</b></TableCell>];
        let tradeStageRow = [<TableCell colSpan={1}><b>Trade Stages</b></TableCell>];
        sectors.forEach((sector) => {
            subsectorRow.push(<TableCell style={{background:"black", color:"white"}} colSpan={sector.data.length}><b>{sector.sector}</b></TableCell>);
            sector.data.forEach((subsector) => {
                columnCount++;
                nameRow.push(<TableCell>{subsector.sector}</TableCell>);

                let buySellsColor = null;
                if(subsector.directionBias === "Up"){
                    biasRow.push(<TableCell align="center" style={{"background-color":'rgba(146, 208, 80, 1)'}}>{subsector.directionBias}</TableCell>);
                    tradeDirectionRow.push(<TableCell align="center" style={{"background-color":'rgba(146, 208, 80, 1)'}}>{subsector.tradeDirectionBreakdown.buys} / {subsector.tradeDirectionBreakdown.sells}</TableCell>);
                }
                else if(subsector.directionBias === "Down"){
                    biasRow.push(<TableCell align="center" style={{"background-color":'red', color:"white"}}>{subsector.directionBias}</TableCell>);
                    tradeDirectionRow.push(<TableCell align="center" style={{"background-color":'red', color:"white"}}>{subsector.tradeDirectionBreakdown.buys} / {subsector.tradeDirectionBreakdown.sells}</TableCell>);
                }
                else{
                    biasRow.push(<TableCell align="center" style={{"background-color":'#fcba03'}}>{subsector.directionBias}</TableCell>);
                    tradeDirectionRow.push(<TableCell align="center" style={{"background-color":'#fcba03'}}>{subsector.tradeDirectionBreakdown.buys} / {subsector.tradeDirectionBreakdown.sells}</TableCell>);
                }
                if(subsector.reversals === 0){
                    reversalsRow.push(<TableCell align="center">None</TableCell>);
                }
                else{
                    reversalsRow.push(<TableCell align="center">{subsector.reversals} reversals</TableCell>);
                }
                if(subsector.directionBias === "Up" && subsector.buyStage !== 0){
                    tradeStageRow.push(<TableCell align="center" style={{"background-color":'rgba(146, 208, 80, ' + subsector.buyStage + ')'}}>Near Future</TableCell>);
                }
                else if(subsector.directionBias === "Down" && subsector.sellStage !== 0){
                    tradeStageRow.push(<TableCell align="center" style={{"background-color":'rgba(255, 0, 0, ' + subsector.sellStage + ')'}}>Near Future</TableCell>);
                }
                else{
                   tradeStageRow.push(<TableCell align="center">None</TableCell>);
                }
            })
        })
        tableBody = <TableBody><TableRow>{subsectorRow}</TableRow><TableRow>{nameRow}</TableRow><TableRow>{biasRow}</TableRow><TableRow>{tradeDirectionRow}</TableRow><TableRow>{reversalsRow}</TableRow><TableRow>{tradeStageRow}</TableRow></TableBody>
        return tableBody;
    }
    else{
        return (
            <TableBody />
        );
    }
}

const useStyles = makeStyles((theme) => ({
    cardContent: {
      padding: theme.spacing(3)
    },
    tableHeader: {
      background: "#000000 !important",
      color: "#ffffff !important"
    }
  }));

function DashboardBreakdown(props) {
    var _ = require('lodash');

    const classes = useStyles();
    const [majorIndexes, setMajorIndexes] = useState({"sp500": null, "nasdaq": null, "dowjones": null, "russell2k": null});
    const [numOfSectors, setNumOfSectors] = useState(0);
    const [majorSectors, setMajorSectors] = useState([]);

    useEffect(async() => {
        let stocks = props.stockData.rows;
        let sectorCount = 0;
        if(stocks && stocks.length > 0){
            //Major Indexes
            let sp500 = stocks.filter(obj => { return obj.ticker === "S.SPY"; })[0];
            let nasdaq = stocks.filter(obj => { return obj.ticker === "S.QQQ"; })[0];
            let dowJones = stocks.filter(obj => { return obj.ticker === "S.DIA"; })[0];
            let russell2k = stocks.filter(obj => { return obj.ticker === "S.IWM"; })[0];
            console.log({"sp500": sp500.day_start, "nasdaq": nasdaq.day_start, "dowjones": dowJones.day_start, "russell2k": russell2k.day_start})
            setMajorIndexes({"sp500": sp500.day_start, "nasdaq": nasdaq.day_start, "dowjones": dowJones.day_start, "russell2k": russell2k.day_start});

            //Split stocks up into sectors and subsectors
            const sectors = [...new Set(stocks.map(item => item.sector))];
            let sectorizedStocks = {};
            sectors.forEach(function (stockSector) {
                let stockSectorName = stockSector;
                sectorizedStocks[stockSectorName] = [];
                let stocksFromSector = stocks.filter(obj => { return obj.sector === stockSector; })
                const grouped = _.groupBy(stocksFromSector, item => item.subsector)
                for (const [key, stocks] of Object.entries(grouped)) {
                    //Calculate subsector bias
                    let stockCount = stocks.length;

                    let upwardBias = 0;
                    let downwardBias = 0;
                    let directionBias = null;
                    upwardBias = stocks.filter(obj => { return obj.day_start === "BULL START"; }).length;
                    downwardBias = stocks.filter(obj => { return obj.day_start === "BEAR START"; }).length;
                    if(upwardBias === stockCount){
                        directionBias = "Up"
                    }
                    else if(downwardBias === stockCount){
                        directionBias = "Down"
                    }
                    else{
                        directionBias = "Mixed"
                    }

                    let buys = 0;
                    let sells = 0;
                    buys = stocks.filter(obj => { return obj.bull === "BUY"; }).length;
                    sells = stocks.filter(obj => { return obj.bear === "SELL"; }).length;
                    let tradeDirectionBreakdown = {"buys":buys, "sells": sells};

                    //let reversalsAmount = stocks.filter(obj => { return obj.reversal !== "-"; }).length;
                    let reversalsAmount = 0;
                    let bLook = stocks.filter(obj => { return (obj.buperf === "LOOK" && obj.bull === "BUY"); }).length;
                    let bSoon = stocks.filter(obj => { return (obj.buperf === "SOON" && obj.bull === "BUY"); }).length;
                    let bNow = stocks.filter(obj => { return (obj.buperf === "NOW" && obj.bull === "BUY"); }).length;
                    let sLook = stocks.filter(obj => { return (obj.shperf === "LOOK" && obj.bear === "SELL"); }).length;
                    let sSoon = stocks.filter(obj => { return (obj.shperf === "SOON" && obj.bear === "SELL"); }).length;
                    let sNow = stocks.filter(obj => { return (obj.shperf === "NOW" && obj.bear === "SELL"); }).length;

                    let buyStage = ((bLook * 1) + (bSoon * 2) + (bNow * 3)) / (stockCount * 3);
                    let sellStage = ((sLook * 1) + (sSoon * 2) + (sNow * 3)) / (stockCount * 3);

                    sectorizedStocks[stockSectorName].push({"sector": key, "total": stockCount, "directionBias": directionBias, "tradeDirectionBreakdown": tradeDirectionBreakdown, "reversals": reversalsAmount, "buyStage": buyStage, "sellStage": sellStage});
                    sectorCount = sectorCount + 1;
                }
            });
            setNumOfSectors(sectorCount);
            setMajorSectors(sectorizedStocks);
        }
    }, [props]
    );
    
    return (
        <Card variant="outlined" style={{marginBottom:"25px"}}>
            <CardContent>
                <h2>Market Overview</h2>
                <div style={{ width:"100%" }}>
                    <TableContainer component={Paper} sx={{ maxHeight: 500, marginBottom:"25px" }}>
                    <Table
                        size="small"
                        stickyHeader
                        aria-label="collapsible table"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    colSpan={4}
                                    align="left"
                                    className={classes.tableHeader}
                                ><b>Major Indexes</b></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell
                                    align="center"
                                ><b>{"S&P 500"}</b></TableCell>
                                <TableCell
                                    align="center"
                                ><b>{"Nasdaq"}</b></TableCell>
                                <TableCell
                                    align="center"
                                ><b>{"Dow Jones"}</b></TableCell>
                                <TableCell
                                    align="center"
                                ><b>{"Russell 2000"}</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        <TableRow>
                                {/*{majorIndexes.sp500 === "BULL START" ? <div style={{color:"green"}}>{"\u25B2"}</div> : <div style={{color:"red"}}>{"\u25BC"}</div>}*/}
                                {majorIndexes.sp500 === "BEAR START" && <TableCell align="center" style={{"background-color":'red', color: "white"}}>Downward Bias</TableCell>}
                                {majorIndexes.sp500 === "BULL START" && <TableCell align="center" style={{background: "#92d050", color: "black" }}>Upward Bias</TableCell>}
                                {majorIndexes.sp500 === 'undefined' && <TableCell align="center"></TableCell>}

                                {majorIndexes.nasdaq === "BEAR START" && <TableCell align="center" style={{"background-color":'red', color: "white"}}>Downward Bias</TableCell>}
                                {majorIndexes.nasdaq === "BULL START" && <TableCell align="center" style={{background: "#92d050", color: "black" }}>Upward Bias</TableCell>}
                                {majorIndexes.nasdaq === 'undefined' && <TableCell align="center"></TableCell>}

                                {majorIndexes.dowjones === "BEAR START" && <TableCell align="center" style={{"background-color":'red', color: "white"}}>Downward Bias</TableCell>}
                                {majorIndexes.dowjones === "BULL START" && <TableCell align="center" style={{background: "#92d050", color: "black" }}>Upward Bias</TableCell>}
                                {majorIndexes.dowjones === 'undefined' && <TableCell align="center"></TableCell>}

                                {majorIndexes.russell2k === "BEAR START" && <TableCell align="center" style={{"background-color":'red', color: "white"}}>Downward Bias</TableCell>}
                                {majorIndexes.russell2k === "BULL START" && <TableCell align="center" style={{background: "#92d050", color: "black" }}>Upward Bias</TableCell>}
                                {majorIndexes.russell2k === 'undefined' && <TableCell align="center"></TableCell>}
                            </TableRow>
                        </TableBody>
                    </Table>
                    </TableContainer>
                    <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
                    <Table
                        size="small"
                        stickyHeader
                        aria-label="collapsible table"
                    >
                        <TableHead>
                            <TableRow>
                            <TableCell style={{background: "black"}} colSpan={1}></TableCell>
                                <TableCell
                                    colSpan={numOfSectors}
                                    align="left"
                                    className={classes.tableHeader}
                                ><b>ETFs</b></TableCell>
                            </TableRow>
                        </TableHead>
                        {breakdownBodyGen(majorSectors)}
                    </Table>
                    </TableContainer>
                </div>
            </CardContent>
        </Card>
    );
}

export default DashboardBreakdown;
