import React from "react";
import Meta from "./../components/Meta";
import UserManagementSection from "./../components/UserManagement";
import { useRouter } from "./../util/router";
import { requireAuth, useAuth } from "./../util/auth";

function UserManagementPage(props) {
  const auth = useAuth();

  return (
    <>
      <Meta title="Manage Users" />

      {auth.user.admin === true ? (
        <UserManagementSection
          bgColor="default"
          size="medium"
          bgImage=""
          bgImageOpacity={1}
          title="Manage Users"
          subtitle=""
        />
      ) : (
        <div style={{ textAlign: "center", paddingTop: "50px" }}>
          <i>
            You do not have the necessary privileges. Contact the Administrator.
          </i>
        </div>
      )}
    </>
  );
}

export default requireAuth(UserManagementPage);
