import React, {useEffect} from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import { Link } from "./../util/router";
import lottie from "lottie-web";
import animation from "../93344-money-investment.json";

function HeroSection2(props) {
  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#animation"),
      animationData: animation,
      renderer: "svg",
      loop: true,
      autoplay: true
    });
  }, []);
  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      style={{"padding-top":'300px', "padding-bottom":'500px'}}
    >
      <Container>
        <Box textAlign="center">
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            size={1}
          />
          <Button
            component={Link}
            to={props.buttonPath}
            variant="contained"
            size="large"
            color={props.buttonColor}
          >
            {props.buttonText}
          </Button>
          <div style={{height: "75vh"}} id="animation" />
        </Box>
      </Container>
    </Section>
  );
}

export default HeroSection2;
