import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
//import { useAuth } from "./../util/auth";
//import { useRouter } from "./../util/router";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import TablePagination from '@mui/material/TablePagination';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(3)
  },
  tableHeader: {
    background: "#000000 !important",
    color: "#ffffff !important"
  }
}));

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired
};

const actionChecker = function (row) {
  return (
    (row.day_start === "BULL START" && row.bull === "BUY" && row.buperf9 === "NOW" && row.perf_noperf_pos === "Perfect") || 
    (row.day_start === "BEAR START" && row.bear === "SELL" && row.shperf9 === "NOW" && row.perf_noperf_neg === "Perfect")
  );
};

const confidenceGen = function(row) {
  if(row.day_start === "BULL START" && row.bull === "BUY"){
    if (row.perf_noperf_pos === "Perfect"){
      return(
        <TableCell
          style={{ background: "#92d050", color: "black" }}
          align="center"
        >
          Perfect
        </TableCell>
      )
    }
    else{
      return (
        <TableCell />
      );
    }
  }
  else if (row.day_start === "BEAR START" && row.bear === "SELL"){
    if (row.perf_noperf_neg === "Perfect"){
      return(
        <TableCell
          style={{ background: "red", color: "white" }}
          align="center"
        >
          Perfect
        </TableCell>
      )
    }
    else{
      return (
        <TableCell />
      );
    }
  }
  else{
    return (
      <TableCell />
    );
  }
}

const stageGen = function(row) {
  if(row.day_start === "BULL START" && row.bull === "BUY"){
    if(row.buperf9 === "NOW"){
      <TableCell
        style={{ background: "#92d050", color: "black" }}
        align="center"
      >
        Now
      </TableCell>
    }
    else if(row.buperf9 === "LOOK"){
      <TableCell
        style={{ background: "#ebf1de", color: "black" }}
        align="center"
      >
        Look
      </TableCell>
    }
    else if(row.buperf9 === "SOON"){
      <TableCell
        style={{ background: "#d8e4bc", color: "black" }}
        align="center"
      >
        Soon
      </TableCell>
    }
    else if(row.buperf9 === "-"){
      return (
        <TableCell />
      );
    }
  }
  else if (row.day_start === "BEAR START" && row.bear === "SELL"){
    if(row.shperf9 === "NOW"){
      <TableCell
        style={{ background: "red", color: "white" }}
        align="center"
      >
        Now
      </TableCell>
    }
    else if(row.shperf9 === "LOOK"){
      <TableCell
        style={{ background: "#f2dcdb", color: "black" }}
        align="center"
      >
        Look
      </TableCell>
    }
    else if(row.shperf9 === "SOON"){
      <TableCell
        style={{ background: "#da9694", color: "black" }}
        align="center"
      >
        Soon
      </TableCell>
    }
    else if(row.shperf9 === "-"){
      return (
        <TableCell />
      );
    }
  }
  else{
    return (
      <TableCell />
    );
  }
}

Row.propTypes = {
  row: PropTypes.shape({
    pkey: PropTypes.string.isRequired,
    stock_name: PropTypes.string.isRequired,
    industry: PropTypes.string,
    percent_return: PropTypes.string,
    day_start: PropTypes.string.isRequired,
    bull: PropTypes.string,
    bear: PropTypes.string,
    shperf9: PropTypes.string.isRequired,
    buperf9: PropTypes.string.isRequired,
    perf_noperf_neg: PropTypes.string.isRequired,
    perf_noperf_pos: PropTypes.string.isRequired,
    timescale: PropTypes.string,
    time_stock: PropTypes.string,
    date_stock: PropTypes.string,
    time_updated: PropTypes.string,
    date_updated: PropTypes.string,
    rsimin: PropTypes.string,
    prsimin: PropTypes.string,
    pmin: PropTypes.string,
    pmax: PropTypes.string,
    pminrsi: PropTypes.string,
    pmaxrsi: PropTypes.string,
    atr: PropTypes.string,
    rsi14: PropTypes.string,
    avg_atr: PropTypes.string,
    neg_div_tier_1: PropTypes.string,
    neg_div_tier_2: PropTypes.string,
    rsimin_tier_2_div: PropTypes.string,
    pmin_tier_2_div: PropTypes.string,
    rsi_breach_tier_2: PropTypes.string,
    pos_div_tier_1: PropTypes.string,
    minprsi_pos: PropTypes.string,
    minprsi_neg: PropTypes.string,
    highest_current_p: PropTypes.string,
    wow_for_reversal: PropTypes.string,
    go_bear: PropTypes.string,
    go_bull: PropTypes.string,
    reversal: PropTypes.string,
    rank_: PropTypes.string,
    bear_a: PropTypes.string,
    bear_b: PropTypes.string,
    bull_a: PropTypes.string,
    bull_b: PropTypes.string,
    reversal_a: PropTypes.string,
    reversal_b: PropTypes.string,
    rsimax: PropTypes.string,
    prsimax: PropTypes.string,
    pinned: PropTypes.bool
  }).isRequired
};

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" align="left">
          {row.pkey}
        </TableCell>
        <TableCell component="th" scope="row" align="center">
          <b>{row.stock_name}</b>
        </TableCell>
        <TableCell align="center">{row.industry}</TableCell>
        <TableCell component="th" scope="row" align="center">
          {row.time_stock}
        </TableCell>
        {row.day_start === "BULL START" && (
          <TableCell
            style={{ background: "#92d050", color: "black" }}
            align="center"
          >
            BULL
          </TableCell>
        )}
        {row.day_start === "BEAR START" && (
          <TableCell
            style={{ background: "red", color: "white" }}
            align="center"
          >
            BEAR
          </TableCell>
        )}
        {row.day_start !== "BEAR START" && row.day_start !== "BULL START" && (
          <TableCell align="center"></TableCell>
        )}
        {row.bull === "BUY" && (
          <TableCell
            style={{ background: "#92d050", color: "black" }}
            align="center"
          >
            BUY
          </TableCell>
        )}
        {row.bear === "SELL" && (
          <TableCell
            style={{ background: "red", color: "white" }}
            align="center"
          >
            SELL
          </TableCell>
        )}
        {row.bear !== "SELL" && row.bull !== "BUY" && (
          <TableCell align="center"></TableCell>
        )}
        {stageGen(row)}
        {confidenceGen(row)}
        {actionChecker(row) ? (
          <TableCell
            style={{ background: "red", color: "white" }}
            align="center"
          >
            EXECUTE
          </TableCell>
        ) : (
          <TableCell></TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Additional Metrics
              </Typography>
              <Table size="small" aria-label="additional-metrics">
                <TableBody>
                  <TableRow>
                    <TableCell align="left">
                      <b>Bull: </b>
                      {row.bull}
                    </TableCell>
                    <TableCell align="left">
                      <b>Go Bull: </b>
                      {row.go_bull}
                    </TableCell>
                    <TableCell align="left">
                      <b>Bull A: </b>
                      {row.bull_a}
                    </TableCell>
                    <TableCell align="left">
                      <b>Bull B: </b>
                      {row.bull_b}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      <b>Bear: </b>
                      {row.bear}
                    </TableCell>
                    <TableCell align="left">
                      <b>Go Bear: </b>
                      {row.go_bear}
                    </TableCell>
                    <TableCell align="left">
                      <b>Bear A: </b>
                      {row.bear_a}
                    </TableCell>
                    <TableCell align="left">
                      <b>Bear B: </b>
                      {row.bear_b}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      <b>Reversal: </b>
                      {row.reversal}
                    </TableCell>
                    <TableCell align="left">
                      <b>Wow for Reversal: </b>
                      {row.wow_for_reversal}
                    </TableCell>
                    <TableCell align="left">
                      <b>Reversal A: </b>
                      {row.reversal_a}
                    </TableCell>
                    <TableCell align="left">
                      <b>Reversal B: </b>
                      {row.reversal_b}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      <b>shperf9: </b>
                      {row.shperf9}
                    </TableCell>
                    <TableCell align="left">
                      <b>buperf9: </b>
                      {row.buperf9}
                    </TableCell>
                    <TableCell align="left">
                      <b>perf_noperf_neg: </b>
                      {row.perf_noperf_neg}
                    </TableCell>
                    <TableCell align="left">
                      <b>perf_noperf_pos: </b>
                      {row.perf_noperf_pos}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      <b>RSI Min: </b>
                      {row.rsimin}
                    </TableCell>
                    <TableCell align="left">
                      <b>PRSI Min: </b>
                      {row.prsimin}
                    </TableCell>
                    <TableCell align="left">
                      <b>P Min: </b>
                      {row.pmin}
                    </TableCell>
                    <TableCell align="left">
                      <b>P Max: </b>
                      {row.pmax}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      <b>PMIN RSI: </b>
                      {row.pminrsi}
                    </TableCell>
                    <TableCell align="left">
                      <b>PMAX RSI: </b>
                      {row.pmaxrsi}
                    </TableCell>
                    <TableCell align="left">
                      <b>RSI14: </b>
                      {row.rsi14}
                    </TableCell>
                    <TableCell align="left">
                      <b>neg_div_tier_1: </b>
                      {row.neg_div_tier_1}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      <b>neg_div_tier_2: </b>
                      {row.neg_div_tier_2}
                    </TableCell>
                    <TableCell align="left">
                      <b>rsimin_tier_2_div: </b>
                      {row.rsimin_tier_2_div}
                    </TableCell>
                    <TableCell align="left">
                      <b>rsi_breach_tier_2: </b>
                      {row.rsi_breach_tier_2}
                    </TableCell>
                    <TableCell align="left">
                      <b>pos_div_tier_1: </b>
                      {row.pos_div_tier_1}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      <b>minprsi_pos: </b>
                      {row.minprsi_pos}
                    </TableCell>
                    <TableCell align="left">
                      <b>minprsi_neg: </b>
                      {row.minprsi_neg}
                    </TableCell>
                    <TableCell align="left">
                      <b>highest_current_p: </b>
                      {row.highest_current_p}
                    </TableCell>
                    <TableCell align="left">
                      <b>ATR: </b>
                      {row.atr}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      <b>Average ATR: </b>
                      {row.avg_atr}
                    </TableCell>
                    <TableCell align="left">
                      <b>RSI Max: </b>
                      {row.rsimax}
                    </TableCell>
                    <TableCell align="left">
                      <b>PRSI Max: </b>
                      {row.prsimax}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">
                      Date Stock: {row.date_stock}
                    </TableCell>
                    <TableCell align="left">
                      Time Updated: {row.time_updated}
                    </TableCell>
                    <TableCell align="left">
                      Date Updated: {row.date_updated}
                    </TableCell>
                    <TableCell align="left">
                      Time Scale: {row.timescale}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function HistoricalDataSection(props) {
  const classes = useStyles();

  //const auth = useAuth();
  //const router = useRouter();

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(true);
  const [rows, setRows] = useState([]);
  const [date, setDate] = React.useState(new Date());
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [numOfPages, setNumOfPages] = React.useState(0);
  const [numberOfResults, setNumberOfResults] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if(date !== "" && date !== new Date()){
        setIsLoaded(false);
        fetch(
            "https://6ibkst7jq0.execute-api.us-east-2.amazonaws.com/test/historicaldata?date=" + date.toISOString().split('T')[0] + "&page=" + page
        )
        .then((res) => res.json())
        .then(
            (result) => {
            if (result.records !== []) {
                setRows(result.records);
                setNumOfPages(result.pages);
                setNumberOfResults(result.record_count);
                setIsLoaded(true);
            }
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                setIsLoaded(true);
                setError(error);
            }
        );
    }
    return (setRows([]))
  }, [date,page]);

  if (isLoaded) {
    return (
      <Section
        bgColor={props.bgColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
      >
        <Container>
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            size={4}
            textAlign="left"
          />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                label="Select a Date"
                value={date}
                onChange={(newValue) => {
                    setDate(newValue);
                }}
                format="YYYY-MM-DD"
                maxDate={new Date()}
                renderInput={(params) => <TextField {...params} />}
            />
        </LocalizationProvider>
          {rows.length !== 0 && (
            <Grid container={true} spacing={4} style={{ marginTop: "15px" }}>
              <Grid item={true} xs={12} md={12}>
                <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
                  <Table
                    size="small"
                    stickyHeader
                    aria-label="collapsible table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className={classes.tableHeader}
                          align="center"
                        ></TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          align="left"
                        >
                          pkey
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          align="center"
                        >
                          Ticker
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          align="center"
                        >
                          Sector
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          align="center"
                        >
                          Time Stamp
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          align="center"
                        >
                          Day Start
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          align="center"
                        >
                          Trade Direction
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          align="center"
                        >
                          Stage
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          align="center"
                        >
                          Confidence
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          align="center"
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* Tickers With Action */}
                      {rows != null &&
                        rows !== [] &&
                        rows
                          .filter(
                            (row) =>
                              actionChecker(row)
                          )
                          .map((row) => <Row key={row.pkey} row={row} />)}
                      {/* Tickers Without Action */}
                      {rows != null &&
                        rows !== [] &&
                        rows
                          .filter(
                            (row) =>
                              !actionChecker(row)
                          )
                          .map((row) => (
                            <Row
                              key={row.pkey}
                              row={row}
                              onPinChange={setRows}
                            />
                          ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                rowsPerPageOptions={[25]}
                  colSpan={3}
                  count={numberOfResults}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  ActionsComponent={TablePaginationActions}
                />
              </Grid>
            </Grid>
          )}
          {(rows.length === 0 && date === "") && (
            <div style={{ textAlign: "center" }}>Select a date.</div>
          )}
          {(rows.length === 0 && date !== "") && (
            <div style={{ textAlign: "center" }}>No entries for selected date.</div>
          )}
        </Container>
      </Section>
    );
  } else {
    return (
      <Section
        bgColor={props.bgColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
      >
        <Container>
          <SectionHeader title={props.title} size={4} textAlign="left" />
          {error === null && (
            <div style={{ textAlign: "center" }}>
              <CircularProgress />
            </div>
          )}
          {error !== null && <div style={{ textAlign: "center" }}>{error}</div>}
        </Container>
      </Section>
    );
  }
}

export default HistoricalDataSection;
