import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
//import { useAuth } from "./../util/auth";
//import { useRouter } from "./../util/router";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CircularProgress from "@mui/material/CircularProgress";
import DashboardSignificantEvents from "./DashboardSignificantEvents";
import DashboardBreakdown from "./DashboardBreakdown";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(3)
  },
  tableHeader: {
    background: "#000000 !important",
    color: "#ffffff !important"
  }
}));

let openTickers = [];

const pressureChecker = function (row){
  return (
    (row.continousPressureB == "true" && row.day_start === "BULL START") || 
    (row.continousPressureA == "true" && row.day_start === "BEAR START")
  );
}

const cpaChecker = function (row){
  return (
    (row.continousPressureB == "true" && row.day_start === "BULL START")
  );
}

const cpbChecker = function (row){
  return (
    (row.continousPressureA == "true" && row.day_start === "BEAR START")
  );
}

const actionChecker = function (row) {
  return (
    (row.day_start === "BULL START" && row.bull === "BUY" && row.buperf === "NOW" && row.perf_noperf_pos === "PERF" && !cpaChecker(row)) || 
    (row.day_start === "BEAR START" && row.bear === "SELL" && row.shperf === "NOW" && row.perf_noperf_neg === "PERF" && !cpbChecker(row))
  );
};

const stageNowChecker = function (row) {
  return (
    (row.day_start === "BULL START" && row.bull === "BUY" && row.buperf === "NOW" && row.perf_noperf_pos === "NOT YET" && !cpaChecker(row)) || 
    (row.day_start === "BEAR START" && row.bear === "SELL" && row.shperf === "NOW" && row.perf_noperf_neg === "NOT YET" && !cpbChecker(row))
  );
};

const stageSoonChecker = function (row) {
  return (
    (row.day_start === "BULL START" && row.bull === "BUY" && row.buperf === "SOON" && !cpaChecker(row)) || 
    (row.day_start === "BEAR START" && row.bear === "SELL" && row.shperf === "SOON" && !cpbChecker(row))
  );
};

const stageLookChecker = function (row) {
  return (
    (row.day_start === "BULL START" && row.bull === "BUY" && row.buperf === "LOOK" && !cpaChecker(row)) || 
    (row.day_start === "BEAR START" && row.bear === "SELL" && row.shperf === "LOOK" && !cpbChecker(row))
  );
};

const directionChecker = function (row) {
  return (
    (row.day_start === "BULL START" && row.bull === "BUY" && row.buperf === "-" && row.dCountB != 10 && !cpaChecker(row)) || 
    (row.day_start === "BEAR START" && row.bear === "SELL" && row.shperf === "-" && row.dCountA != 10 && !cpbChecker(row))
  );
};

const startChecker = function (row) {
  return (
    (row.day_start === "BULL START" && row.bull === "-" && !cpaChecker(row)) || 
    (row.day_start === "BEAR START" && row.bear === "-" && !cpbChecker(row))
  );
};

const noneChecker = function (row) {
  return (
    (row.day_start !== "BULL START") && 
    (row.day_start !== "BEAR START")
  );
};

const isTickerOpen = (row) => {
  return openTickers.includes(row.ticker);
}

const rowGen = function(row){
  if(cpbChecker(row)){
    return(
      <TableCell
        style={{ background: "red", color: "white" }}
        align="center"
        colSpan={6}
      >
        CONTINUOUS SELLING PRESSURE
      </TableCell>
    )
  }
  else if(cpaChecker(row)){
    return(
      <TableCell
        style={{ background: "#92d050", color: "black" }}
        align="center"
        colSpan={6}
      >
        CONTINUOUS BUYING PRESSURE
      </TableCell>
    )
  }
  return(
    <>
    {row.day_start === "BULL START" && (
      <TableCell
        style={{ background: "#92d050", color: "black" }}
        align="center"
      >
        BULL
      </TableCell>
    )}
    {row.day_start === "BEAR START" && (
      <TableCell
        style={{ background: "red", color: "white" }}
        align="center"
      >
        BEAR
      </TableCell>
    )}
    {row.day_start !== "BEAR START" && row.day_start !== "BULL START" && (
      <TableCell align="center"></TableCell>
    )}
    {row.bull === "BUY" && (
      <TableCell
        style={{ background: "#92d050", color: "black" }}
        align="center"
      >
        NEGATIVE
      </TableCell>
    )}
    {row.bear === "SELL" && (
      <TableCell
        style={{ background: "red", color: "white" }}
        align="center"
      >
        POSITIVE
      </TableCell>
    )}
    {row.bear !== "SELL" && row.bull !== "BUY" && (
      <TableCell align="center"></TableCell>
    )}
    {stageGen(row)}
    {confidenceGen(row)}
    {timeToActionGen(row)}
    </>
  )
}

const confidenceGen = function(row) {
  if(row.day_start === "BULL START" && row.bull === "BUY" && row.buperf === "NOW"){
    if (row.perf_noperf_pos === "PERF"){
      return(
        <>
          <TableCell
            style={{ background: "#92d050", color: "black" }}
            align="center"
          >
            Increased
          </TableCell>
            <TableCell
            style={{ background: "#92d050", color: "black" }}
            align="center"
          >
            LONG/COVER
          </TableCell>
        </>
      )
    }
    else{
      return (
        <>
          <TableCell
            style={{ background: "#d8e4bc", color: "black" }}
            align="center"
          >
            Variable
          </TableCell>
          <TableCell
            style={{ background: "#d8e4bc", color: "black" }}
            align="center"
          >
            LONG/COVER
          </TableCell>
        </>
      );
    }
  }
  else if (row.day_start === "BEAR START" && row.bear === "SELL" && row.shperf === "NOW"){
    if (row.perf_noperf_neg === "PERF"){
      return(
        <>
          <TableCell
            style={{ background: "red", color: "white" }}
            align="center"
          >
            Increased
          </TableCell>
          <TableCell
            style={{ background: "red", color: "white" }}
            align="center"
          >
            SELL/SHORT
          </TableCell>
        </>
      )
    }
    else{
      return (
        <>
          <TableCell
            style={{ background: "#da9694", color: "black" }}
            align="center"
          >
            Variable
          </TableCell>
          <TableCell
            style={{ background: "#da9694", color: "black" }}
            align="center"
          >
            SELL/SHORT
          </TableCell>
        </>
      );
    }
  }
  else{
    return (
      <><TableCell align="center"></TableCell><TableCell align="center"></TableCell></>
    );
  }
}

const stageGen = function(row) {
  if(row.day_start === "BULL START" && row.bull === "BUY"){
    if(row.buperf === "NOW"){
      return(
        <TableCell
          style={{ background: "#92d050", color: "black" }}
          align="center"
        >
          Now
        </TableCell>
      );
    }
    else if(row.buperf === "LOOK"){
      return(
        <TableCell
          style={{ background: "#ebf1de", color: "black" }}
          align="center"
        >
          Look
        </TableCell>
      );
    }
    else if(row.buperf === "SOON"){
      return(
        <TableCell
          style={{ background: "#d8e4bc", color: "black" }}
          align="center"
        >
          Soon
        </TableCell>
      );
    }
    else if(row.buperf === "-"){
      return (
        <TableCell align="center"></TableCell>
      );
    }
  }
  else if (row.day_start === "BEAR START" && row.bear === "SELL"){
    if(row.shperf === "NOW"){
      return(
        <TableCell
          style={{ background: "red", color: "white" }}
          align="center"
        >
          Now
        </TableCell>
      );
    }
    else if(row.shperf === "LOOK"){
      return(
        <TableCell
          style={{ background: "#f2dcdb", color: "black" }}
          align="center"
        >
          Look
        </TableCell>
      );
    }
    else if(row.shperf === "SOON"){
      return(
        <TableCell
          style={{ background: "#da9694", color: "black" }}
          align="center"
        >
          Soon
        </TableCell>
      );
    }
    else if(row.shperf === "-"){
      return (
        <TableCell align="center"></TableCell>
      );
    }
  }
  else{
    return (
      <TableCell align="center"></TableCell>
    );
  }
}

const timeToActionGen = (row) => {
  if(row.TimeToAction !== null && row.TimeToAction !== "-"){
    return (
      <TableCell
          align="center"
        >
        <i>~ {row.TimeToAction} minutes</i>
      </TableCell>
    );
  }
  else{
    return (
      <TableCell align="center"></TableCell>
    );
  }
}

Row.propTypes = {
  row: PropTypes.shape({
    pkey: PropTypes.string.isRequired,
    ticker: PropTypes.string.isRequired,
    percent_return: PropTypes.string,
    day_start: PropTypes.string.isRequired,
    bull: PropTypes.string,
    bear: PropTypes.string,
    shperf: PropTypes.string.isRequired,
    buperf: PropTypes.string.isRequired,
    perf_noperf_neg: PropTypes.string.isRequired,
    perf_noperf_pos: PropTypes.string.isRequired,
    timescale: PropTypes.string,
    timestamp: PropTypes.string,
    date_stock: PropTypes.string,
    time_updated: PropTypes.string,
    timestamp: PropTypes.string,
    rsimin: PropTypes.string,
    prsimin: PropTypes.string,
    pmin: PropTypes.string,
    pmax: PropTypes.string,
    pminrsi: PropTypes.string,
    pmaxrsi: PropTypes.string,
    atr: PropTypes.string,
    rsi14: PropTypes.string,
    avg_atr: PropTypes.string,
    neg_div_tier_1: PropTypes.string,
    neg_div_tier_2: PropTypes.string,
    rsimin_tier_2_div: PropTypes.string,
    pmin_tier_2_div: PropTypes.string,
    rsi_breach_tier_2: PropTypes.string,
    pos_div_tier_1: PropTypes.string,
    minprsi_pos: PropTypes.string,
    minprsi_neg: PropTypes.string,
    highest_current_p: PropTypes.string,
    wow_for_reversal: PropTypes.string,
    go_bear: PropTypes.string,
    go_bull: PropTypes.string,
    reversal: PropTypes.string,
    rank_: PropTypes.string,
    bear_a: PropTypes.string,
    bear_b: PropTypes.string,
    bull_a: PropTypes.string,
    bull_b: PropTypes.string,
    reversal_a: PropTypes.string,
    reversal_b: PropTypes.string,
    rsimax: PropTypes.string,
    prsimax: PropTypes.string,
    sector: PropTypes.string,
    subsector: PropTypes.string,
    pinned: PropTypes.bool
  }).isRequired
};

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(props.isOpen);

  const handleOpen = (state) => {
    setOpen(state);
    if (!open){
      openTickers.push(row.ticker)
    }
    else{
      const index = openTickers.indexOf(row.ticker);
      if (index > -1) {
        openTickers.splice(index, 1);
      }
    }
  }

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
      <TableCell align="center">
          {props.isAdmin === true &&
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          }
        </TableCell>
        <TableCell component="th" scope="row" align="center">
          <b>{row.ticker}</b>
        </TableCell>
        <TableCell align="center">{row.subsector}</TableCell>
        <TableCell component="th" scope="row" align="center">
          {row.timestamp}
        </TableCell>
        {rowGen(row)}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Additional Metrics
              </Typography>
              <Table size="small" aria-label="additional-metrics">
                <TableBody>      
                <TableRow>
                  <TableCell align="left">
                    <b>day_rsimin: </b>
                    {row.day_rsimin}
                  </TableCell>
                  <TableCell align="left">
                    <b>day_prsimin: </b>
                    {row.day_prsimin}
                  </TableCell>
                  <TableCell align="left">
                    <b>day_rsimax: </b>
                    {row.day_rsimax}
                  </TableCell>
                  <TableCell align="left">
                    <b>day_prsimax: </b>
                    {row.day_prsimax}
                  </TableCell>
                  <TableCell align="left">
                    <b>day_pmin: </b>
                    {row.day_pmin}
                  </TableCell>
                  <TableCell align="left">
                    <b>day_pmax: </b>
                    {row.day_pmax}
                  </TableCell>
              </TableRow>
              <TableRow>
                  <TableCell align="left">
                    <b>day_pminrsi: </b>
                    {row.day_pminrsi}
                  </TableCell>
                  <TableCell align="left">
                    <b>day_pmaxrsi: </b>
                    {row.day_pmaxrsi}
                  </TableCell>
                  <TableCell align="left">
                    <b>day_atr: </b>
                    {row.day_atr}
                  </TableCell>
                  <TableCell align="left">
                    <b>day_avg_atr: </b>
                    {row.day_avg_atr}
                  </TableCell>
                  <TableCell align="left">
                    <b>day_rsi14: </b>
                    {row.day_rsi14}
                  </TableCell>
                  <TableCell align="left">
                    <b>last30_rsimin: </b>
                    {row.last30_rsimin}
                  </TableCell>
              </TableRow>
              <TableRow>
                  <TableCell align="left">
                    <b>last30_prsimin: </b>
                    {row.last30_prsimin}
                  </TableCell>
                  <TableCell align="left">
                    <b>last30_rsimax: </b>
                    {row.last30_rsimax}
                  </TableCell>
                  <TableCell align="left">
                    <b>last30_prsimax: </b>
                    {row.last30_prsimax}
                  </TableCell>
                  <TableCell align="left">
                    <b>last30_pmin: </b>
                    {row.last30_pmin}
                  </TableCell>
                  <TableCell align="left">
                    <b>last30_pmax: </b>
                    {row.last30_pmax}
                  </TableCell>
                  <TableCell align="left">
                    <b>last30_pminrsi: </b>
                    {row.last30_pminrsi}
                  </TableCell>
              </TableRow>
              <TableRow>
                  <TableCell align="left">
                    <b>last30_pmaxrsi: </b>
                    {row.last30_pmaxrsi}
                  </TableCell>
                  <TableCell align="left">
                    <b>last30_atr: </b>
                    {row.last30_atr}
                  </TableCell>
                  <TableCell align="left">
                    <b>last30_avg_atr: </b>
                    {row.last30_avg_atr}
                  </TableCell>
                  <TableCell align="left">
                    <b>last30_rsi14: </b>
                    {row.last30_rsi14}
                  </TableCell>
                  <TableCell align="left">
                    <b>bRange_rsimin: </b>
                    {row.bRange_rsimin}
                  </TableCell>
                  <TableCell align="left">
                    <b>bRange_prsimin: </b>
                    {row.bRange_prsimin}
                  </TableCell>
              </TableRow>
              <TableRow>
                  <TableCell align="left">
                    <b>bRange_rsimax: </b>
                    {row.bRange_rsimax}
                  </TableCell>
                  <TableCell align="left">
                    <b>bRange_prsimax: </b>
                    {row.bRange_prsimax}
                  </TableCell>
                  <TableCell align="left">
                    <b>bRange_pmin: </b>
                    {row.bRange_pmin}
                  </TableCell>
                  <TableCell align="left">
                    <b>bRange_pmax: </b>
                    {row.bRange_pmax}
                  </TableCell>
                  <TableCell align="left">
                    <b>bRange_pminrsi: </b>
                    {row.bRange_pminrsi}
                  </TableCell>
                  <TableCell align="left">
                    <b>bRange_pmaxrsi: </b>
                    {row.bRange_pmaxrsi}
                  </TableCell>
              </TableRow>
              <TableRow>
                  <TableCell align="left">
                    <b>bRange_avg_atr: </b>
                    {row.bRange_avg_atr}
                  </TableCell>
                  <TableCell align="left">
                    <b>aRange_rsimin: </b>
                    {row.aRange_rsimin}
                  </TableCell>
                  <TableCell align="left">
                    <b>aRange_prsimin: </b>
                    {row.aRange_prsimin}
                  </TableCell>
                  <TableCell align="left">
                    <b>aRange_rsimax: </b>
                    {row.aRange_rsimax}
                  </TableCell>
                  <TableCell align="left">
                    <b>aRange_prsimax: </b>
                    {row.aRange_prsimax}
                  </TableCell>
                  <TableCell align="left">
                    <b>aRange_pmin: </b>
                    {row.aRange_pmin}
                  </TableCell>
              </TableRow>
              <TableRow>
                  <TableCell align="left">
                    <b>aRange_pmax: </b>
                    {row.aRange_pmax}
                  </TableCell>
                  <TableCell align="left">
                    <b>aRange_pminrsi: </b>
                    {row.aRange_pminrsi}
                  </TableCell>
                  <TableCell align="left">
                    <b>aRange_pmaxrsi: </b>
                    {row.aRange_pmaxrsi}
                  </TableCell>
                  <TableCell align="left">
                    <b>aRange_avg_atr: </b>
                    {row.aRange_avg_atr}
                  </TableCell>
                  <TableCell align="left">
                    <b>perf_noperf_neg: </b>
                    {row.perf_noperf_neg}
                  </TableCell>
                  <TableCell align="left">
                    <b>perf_noperf_pos: </b>
                    {row.perf_noperf_pos}
                  </TableCell>
              </TableRow>
              <TableRow>
                  <TableCell align="left">
                    <b>neg_div_tier_1: </b>
                    {row.neg_div_tier_1}
                  </TableCell>
                  <TableCell align="left">
                    <b>new_low: </b>
                    {row.newLow}
                  </TableCell>
                  <TableCell align="left">
                    <b>au2: </b>
                    {row.au2}
                  </TableCell>
                  <TableCell align="left">
                    <b>day_start: </b>
                    {row.day_start}
                  </TableCell>
                  <TableCell align="left">
                    <b>firstTierDivNeg: </b>
                    {row.firstTierDivNeg}
                  </TableCell>
              </TableRow>
              <TableRow>
                  <TableCell align="left">
                    <b>rsimin_tier_2_div: </b>
                    {row.rsimin_tier_2_div}
                  </TableCell>
                  <TableCell align="left">
                    <b>pmin_tier_2_div: </b>
                    {row.pmin_tier_2_div}
                  </TableCell>
                  <TableCell align="left">
                    <b>minprsi_pos: </b>
                    {row.minprsi_pos}
                  </TableCell>
                  <TableCell align="left">
                    <b>minprsi_neg: </b>
                    {row.minprsi_neg}
                  </TableCell>
                  <TableCell align="left">
                    <b>highest_current_p: </b>
                    {row.highest_current_p}
                  </TableCell>
              </TableRow>
              <TableRow>
                  <TableCell align="left">
                    <b>pos_div_tier_1: </b>
                    {row.pos_div_tier_1}
                  </TableCell>
                  <TableCell align="left">
                    <b>new high: </b>
                    {row.newHigh}
                  </TableCell>
                  <TableCell align="left">
                    <b>CPA: </b>
                    {row.continousPressureA}
                  </TableCell>
                  <TableCell align="left">
                    <b>CPB: </b>
                    {row.continousPressureB}
                  </TableCell>
                  <TableCell align="left">
                    <b>dCountA: </b>
                    {row.dCountA}
                  </TableCell>
                  <TableCell align="left">
                    <b>dCountB: </b>
                    {row.dCountB}
                  </TableCell>
              </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function DashboardSection(props) {
  const classes = useStyles();

  //const auth = useAuth();
  //const router = useRouter();

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [rows, setRows] = useState([]);

  const pushEntries = (entries) => {
    if(rows.length > 0){
      let existingRows = rows;
      entries.forEach(element => {
        let objIndex = existingRows.findIndex((obj => obj.ticker === element.ticker));
        if(objIndex !== -1){
          existingRows[objIndex] = element;
        }
        else {
          existingRows = [...existingRows, element];
        }
      });
      setRows(existingRows);
    }
    else {
      setRows(entries);
    }
  }

  useEffect(() => {
    const interval = setInterval(async () => {
      await fetch(
        "https://shwraoy12m.execute-api.us-east-1.amazonaws.com/prod/data"
      )
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.Items !== []) {
              pushEntries(result.Items);
              setIsLoaded(true);
            }
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  if (isLoaded) {
    return (
      <Section
        bgColor={props.bgColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
      >
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Container>
          <DashboardSignificantEvents />
          {rows.length !== 0 &&  <DashboardBreakdown stockData={{rows}} />}
        </Container>
        <Container>
          {rows.length !== 0 && (
            <Grid container={true} spacing={4}>
              <Grid item={true} xs={12} md={12}>
                <TableContainer component={Paper} sx={{ maxHeight: 1000 }}>
                  <Table
                    size="small"
                    stickyHeader
                    aria-label="collapsible table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className={classes.tableHeader}
                          align="center"
                        ></TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          align="center"
                        >
                          Ticker
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          align="center"
                        >
                          Sector
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          align="center"
                        >
                          Last Updated
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          align="center"
                        >
                          Day Start
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          align="center"
                        >
                          Divergence
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          align="center"
                        >
                          End of Divergence
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          align="center"
                        >
                          Confidence
                        </TableCell>
                        {/*<TableCell
                          className={classes.tableHeader}
                          align="center"
                        >
                          Reversal
                        </TableCell>*/}
                        <TableCell
                          className={classes.tableHeader}
                          align="center"
                        >
                          Action
                        </TableCell>
                        <TableCell
                          className={classes.tableHeader}
                          align="center"
                        >
                          Time To Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* Tickers With Pressure */}
                      {rows != null &&
                        rows !== [] &&
                        rows
                          .filter(
                            (row) =>
                            pressureChecker(row)
                          )
                          .map((row) => <Row key={row.pkey} row={row} isOpen={isTickerOpen(row)} isAdmin={props.isAdmin}/>)}
                      {/* Tickers With Action */}
                      {rows != null &&
                        rows !== [] &&
                        rows
                          .filter(
                            (row) =>
                              actionChecker(row)
                          )
                          .map((row) => <Row key={row.pkey} row={row} isOpen={isTickerOpen(row)} isAdmin={props.isAdmin}/>)}
                      {/* Tickers with NOW stage */}
                      {rows != null &&
                        rows !== [] &&
                        rows
                          .filter(
                            (row) =>
                              stageNowChecker(row)
                          )
                          .map((row) => <Row key={row.pkey} row={row} isOpen={isTickerOpen(row)} isAdmin={props.isAdmin}/>)}
                      {/* Tickers with SOON stage */}
                      {rows != null &&
                        rows !== [] &&
                        rows
                          .filter(
                            (row) =>
                              stageSoonChecker(row)
                          )
                          .map((row) => <Row key={row.pkey} row={row} isOpen={isTickerOpen(row)} isAdmin={props.isAdmin}/>)}
                      {/* Tickers with LOOK stage */}
                      {rows != null &&
                        rows !== [] &&
                        rows
                          .filter(
                            (row) =>
                              stageLookChecker(row)
                          )
                          .map((row) => <Row key={row.pkey} row={row} isOpen={isTickerOpen(row)} isAdmin={props.isAdmin}/>)}
                      {/* Tickers with Direction */}
                      {rows != null &&
                        rows !== [] &&
                        rows
                          .filter(
                            (row) =>
                              directionChecker(row)
                          )
                          .map((row) => <Row key={row.pkey} row={row} isOpen={isTickerOpen(row)} isAdmin={props.isAdmin}/>)}
                      {/* Tickers with Start */}
                      {rows != null &&
                        rows !== [] &&
                        rows
                          .filter(
                            (row) =>
                              startChecker(row)
                          )
                          .map((row) => <Row key={row.pkey} row={row} isOpen={isTickerOpen(row)} isAdmin={props.isAdmin}/>)}
                      {/* Tickers with nothing */}
                      {rows != null &&
                        rows !== [] &&
                        rows
                          .filter(
                            (row) =>
                              noneChecker(row)
                          )
                          .map((row) => <Row key={row.pkey} row={row} isOpen={isTickerOpen(row)} isAdmin={props.isAdmin}/>)}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          )}
          {rows.length === 0 && (
            <div style={{ textAlign: "center" }}>Algorithm not running.</div>
          )}
        </Container>
      </Section>
    );
  } else {
    return (
      <Section
        bgColor={props.bgColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
      >
        <Container>
          <SectionHeader title={props.title} size={4} textAlign="center" />
          {error === null && (
            <div style={{ textAlign: "center" }}>
              <CircularProgress />
            </div>
          )}
          {error !== null && <div style={{ textAlign: "center" }}>{error}</div>}
        </Container>
      </Section>
    );
  }
}

export default DashboardSection;