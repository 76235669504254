import React, { useState, useEffect } from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function DashboardSignificantEvents() {
    const [events, setEvents] = useState([]);
    const date = new Date().toISOString().split('T')[0];

    useEffect(async() => {
        await fetch()
            .then((res) => res.json())
            .then(
            (result) => {
                if (result.rows !== []) {
                    setEvents(result.records);
                }
            },
            (error) => {}
            );
        }, []
    );
    
    return (
        <Card variant="outlined" style={{marginBottom:"25px"}}>
            <CardContent>
                <h2>Significant Events</h2>
                {events.length > 0 &&
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                            <TableRow>
                                <TableCell align="left"><b>Event</b></TableCell>
                                <TableCell align="center"><b>Description</b></TableCell>
                                <TableCell align="center"><b>Time</b></TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {events.map((row) => (
                                <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left">{row.title}</TableCell>
                                    <TableCell align="center">{row.description}</TableCell>
                                    <TableCell align="center">{row.time}</TableCell>    
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
                {events.length === 0 && <div><p>No events today</p></div>}
            </CardContent>
        </Card>
    );
}

export default DashboardSignificantEvents;
