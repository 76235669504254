import React from "react";
import Meta from "./../components/Meta";
import SignificantEventsSection from "./../components/SignificantEventsSection";
import { requireAuth, useAuth } from "./../util/auth";

function SignificantEventsPage(props) {
  const auth = useAuth();
  return (
    <>
      <Meta title="Significant Events" />
      {auth.user.admin === true ? (
        <SignificantEventsSection
          bgColor="default"
          size="medium"
          bgImage=""
          bgImageOpacity={1}
          title="Manage Significant Events"
          subtitle=""
          isAdmin={auth.user.admin === true}
        />
      ) : (
        <div style={{ textAlign: "center", paddingTop: "50px" }}>
          <i>
            You do not have the necessary privileges. Contact the Administrator.
          </i>
        </div>
      )}
    </>
  );
}

export default requireAuth(SignificantEventsPage);
