import React from "react";
import Meta from "./../components/Meta";
import DashboardSection from "./../components/DashboardSectionTEST";
import { requireAuth, useAuth } from "./../util/auth";
import Navbar from "./../components/Navbar";

function DashboardPage(props) {
  const auth = useAuth();
  window.analytics.identify(auth.user.uid, {
    name: auth.user.displayName,
    email: auth.user.email,
    admin: auth.user.admin
  });
  return (
    <>
      <Navbar
        color="default"
      />
      <Meta title="Dashboard" />
      {auth.user.canViewDashboard === true ? (
        <DashboardSection
          bgColor="default"
          size="medium"
          bgImage=""
          bgImageOpacity={1}
          title="Acutus in Action"
          subtitle=""
          isAdmin={auth.user.admin === true}
        />
      ) : (
        <div style={{ textAlign: "center", paddingTop: "50px" }}>
          <i>
            You do not have the necessary privileges.
          </i>
        </div>
      )}
    </>
  );
}

export default requireAuth(DashboardPage);
