import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";

function IndexPage(props) {
  return (
    <>
      <Meta />
      <HeroSection
        bgColor="front"
        size="normal"
        bgImage=""
        bgImageOpacity={1}
        title="Acutus"
        subtitle="Mid-Day Market Unlocked"
        buttonText="See the Dashboard"
        buttonColor="primary"
        buttonPath="/dashboard"
      />
    </>
  );
}

export default IndexPage;
