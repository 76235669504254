import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
//import { useAuth } from "./../util/auth";
//import { useRouter } from "./../util/router";
import { listUsers, updateUser, deleteUser } from "./../util/db";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(3)
  },
  tableHeader: {
    background: "#000000 !important",
    color: "#ffffff !important"
  }
}));

function UserManagementSection(props) {
  const classes = useStyles();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    listUsers().then((res) => {
      res.forEach((doc) => {
        let data = doc.data();
        let admin =
          data.admin === undefined || data.admin === false ? false : true;
        let canViewDashboard =
          data.canViewDashboard === undefined || data.canViewDashboard === false
            ? false
            : true;
        setUsers((users) => [
          ...users,
          {
            id: doc.id,
            name: data.name,
            email: data.email,
            admin: admin,
            canViewDashboard: canViewDashboard
          }
        ]);
      });
    });
    return;
  }, []);

  const updateUserProfile = (user) => {
    let id = user.id;
    let update = {
      name: user.name !== undefined ? user.name : "",
      email: user.email,
      admin: user.admin,
      canViewDashboard: user.canViewDashboard
    };
    updateUser(id, update);
  };

  const changeAdmin = (row) => {
    let newSettings = [...users];
    let index = newSettings
      .map(function (e) {
        return e.id;
      })
      .indexOf(row.id);
    row.admin = !row.admin;
    newSettings[index] = row;
    setUsers(newSettings);
    updateUserProfile(row);
  };
  const changeViewDashboard = (row) => {
    let newSettings = [...users];
    let index = newSettings
      .map(function (e) {
        return e.id;
      })
      .indexOf(row.id);
    row.canViewDashboard = !row.canViewDashboard;
    newSettings[index] = row;
    setUsers(newSettings);
    updateUserProfile(row);
  };

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Grid container={true} spacing={4}>
          <Grid item={true} xs={12} md={12}>
            <TableContainer component={Paper} sx={{ maxHeight: 580 }}>
              <Table size="small" stickyHeader aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHeader} align="left">
                      Email
                    </TableCell>
                    <TableCell className={classes.tableHeader} align="left">
                      Name
                    </TableCell>
                    <TableCell className={classes.tableHeader} align="left">
                      Admin
                    </TableCell>
                    <TableCell className={classes.tableHeader} align="left">
                      DashBoard Privileges
                    </TableCell>
                    <TableCell className={classes.tableHeader} align="center">
                      Delete User
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users != null &&
                    users !== [] &&
                    users.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{ "& > *": { borderBottom: "unset" } }}
                      >
                        <TableCell>{row.email}</TableCell>
                        <TableCell>
                          {row.name === undefined || row.name === ""
                            ? "-"
                            : row.name}
                        </TableCell>
                        <TableCell align="left">
                          <Checkbox
                            size="small"
                            checked={row.admin}
                            onChange={() => changeAdmin(row)}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <Checkbox
                            size="small"
                            checked={row.canViewDashboard}
                            onChange={() => changeViewDashboard(row)}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <DeleteForeverIcon
                            style={{ color: "red" }}
                            onClick={() => {
                              if (
                                window.confirm(
                                  "Are you sure you want to delete this user?"
                                )
                              ) {
                                deleteUser(row.id);
                              }
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default UserManagementSection;
